<template lang="html">
  <nav class="mono" :class="{ active: activeMenu }">
    <div class="menu-list" :class="{ active: activeMenu }">
      <a v-for="page in pages" :key="page.uid" @click="changePage(page.uid)">{{ page.title }}</a>
      <a @click="changeLang">{{ $i18n.locale == 'en' ? 'DE' : 'EN' }}</a>
      <span @click="activeMenu = !activeMenu" id="cross"><img class="desktop" src="/public/cross.svg" /><img class="mobile" src="/public/cross_mobile.svg" /></span>
    </div>
    <div v-if="!activeMenu" class="menu-btn clickable" @click="activeMenu = !activeMenu">
      <span id="burger"><img class="desktop" src="/public/burger.svg" /><img class="mobile" src="/public/burger_mobile.svg" /></span>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: false
    }
  },
  computed: {
    pages() {
      return this.$store.state.pages
    }
  },
  mounted: function() {
    this.$root.$on('openMenu', this.openMenu)
    this.$root.$on('closeMenu', this.closeMenu)
  },
  methods: {
    openMenu() {
      this.activeMenu = true
    },
    closeMenu() {
      this.activeMenu = false
    },
    changeLang() {
        this.$i18n.locale === 'en' ? this.$i18n.locale = 'de' : this.$i18n.locale = 'en';
        this.$router.push({
            name: this.$route.name,
            params: { lang: this.$i18n.locale }
        })
        this.$router.go(0);
    },
    changePage(page) {
      if (this.$route.name === page) {
        this.$root.$emit('scrollToTop')
        this.activeMenu = false
      } else {
        this.activeMenu = false
        this.$router.push({
          name: page,
          params: { lang: this.$i18n.locale }
        })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
nav {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    flex-wrap: wrap;
    z-index: 999;
    top: 0;
    left: 50%;
    width: 50%;
    height: auto;
    padding: calc(1 * var(--lh)) calc(1.4 * var(--lh));
    pointer-events: none;
    &.active {
        pointer-events: auto;
        justify-content: space-between;
    }
    .clickable {
        pointer-events: auto;
        flex: 0 0 15%;
        text-align: right;
        @media only screen and (max-width:768px) {
            position: absolute;
            top: 0.4rem;
            right: 0;
            padding: calc(1.2 * var(--lh)) calc(1.4 * var(--lh));
        }
    }
    @media only screen and (max-width:768px) {
        left: 0;
        top: 0;
        width: 100%;
        padding: 0;
        display: block;
    }
    .menu-list {
        flex: 1 0 auto;
        visibility: hidden;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        position: relative;
        flex: 1 0 auto;
        align-items: baseline;
        // transition: opacity 0.2s, visibility 0.2s;
        @media only screen and (max-width:768px) {
            width: 100%;
            padding: calc(1.2 * var(--lh)) calc(1.4 * var(--lh));
            height: 50vh;
            background: white;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

        }
        &.active {
            opacity: 1;
            visibility: visible;
        }
        a,
        a:hover {
            text-decoration: none;
            @media only screen and (max-width:768px) {
                text-align: center;
                font-size: calc(2.5 * var(--fs));
                line-height: 1.15;
            }
        }
        #cross {
            @media only screen and (max-width:768px) {
                position: absolute;
                top: 0.4rem;
                right: 0;
                padding: calc(1.2 * var(--lh)) calc(1.4 * var(--lh));
                img {
                    height: calc(0.8 * var(--fs));
                    @media only screen and (max-width: 768px) {
                        height: calc(1.7 * var(--fs));
                    }
                }
            }
        }
    }
    #burger,
    #cross {
        display: inline-block;
        position: relative;
        height: calc(0.8 * var(--fs));
        @media only screen and (max-width: 768px) {
            height: calc(1.7 * var(--fs));
        }
        img {
            height: 100%;
            &.desktop {
                display: block;
            }
            &.mobile {
                display: none;
            }
            @media only screen and (max-width: 768px) {
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display: block;
                }
            }
        }
    }
    a,
    div {
        color: rgba(0,60,140, 1);
        transition: color 0.5s;
        cursor: pointer;
        text-transform: uppercase;

    }
    a {
        border-bottom: 2px solid rgba(0,0,0, 0);
        &:hover {
            color: rgba(0,60,140, 1);
            border-bottom: 2px solid rgba(0,60,140, 1);
        }
    }
}
</style>
